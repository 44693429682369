import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonContext from '../../../context/CommonContext';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { exportData } from '../../../services/orders/index'
import { dateConverter } from '../../../helpers/commonHelpers'
export default function ExportModal(props) {
    const {show, setShow, channelId, isPickup, isCourierRateCall, setExportCourierRate} = props 
    const {getLabel, constants} = useContext(CommonContext);
    const handleClose = () => {
        setShow(false);
        setCustomDate(false);
        setExportCourierRate(false);
    }

    const [customDate, setCustomDate] = useState(false);

    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [startNewDate, setStartNewDate] = useState(new Date());
    const [endNewDate, setEndNewDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const selectionRange = {startDate: startDate, endDate: endDate, key: 'selection'}
    const [formData, setFormData] = useState({});
    useEffect(() => {
        const initialData = {
            'page': parseInt(1),
            'channels': channelId !== undefined ? [channelId] : [],
            'date_from': '',
            'date_to': '',
            'status': '',
            'is_pickup_order': isPickup
        }
        setFormData(initialData);
    }, [channelId, show, isPickup])

    const handleSelectChange = (event, field) => {
        const value = parseInt(event.target.value);
        const updatedData = {
            ...formData,
            [field]: field === 'status' ? (value ? value : '') : value,
        };
        setFormData(updatedData);

        const newvalue = parseInt(value) === 2 ? true : false;
        if (field === 'date_selection') {
            setCustomDate(newvalue)
            if (newvalue === false) {
                const updatedData = {
                    ...formData,
                    'date_from': '',
                    'date_to': ''
                };
                setFormData(updatedData);
            }else{
                const updatedData = {
                    ...formData,
                    'date_from': startDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', }),
                    'date_to': endDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', })
                };
                setFormData(updatedData);
            }
        }
    };


    const handleSelect = (date) => {
        setstartDate(date.selection.startDate)
        setendDate(date.selection.endDate)
        const start = (date.selection.startDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', }))
        const end = (date.selection.endDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', }))
        setStartNewDate(start)
        setEndNewDate(end)
    };

    const handleSubmit = async() => {
        await exportData(formData, isCourierRateCall);
        handleClose()
    }

    const handleOpenPicker = () => {
        setIsOpen(true)
    }
    const handleClosePicker = () => {
        setIsOpen(false)
    }
    const handleApplyDatePicker = () => {
        const updatedData = {
            ...formData,
            'date_from': startNewDate,
            'date_to': endNewDate,
        };
        setFormData(updatedData)
        setIsOpen(false)
    }
    return (
            <>
            <Modal show={show} onHide={handleClose} className='location third-party export'>
                <Modal.Header closeButton >
                    <Modal.Title >{getLabel('LBL_Export_order_list')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isCourierRateCall === false
                        ? 
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label">{getLabel('LBL_Order_status')}</label>
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <select name="status" onChange={(event) => handleSelectChange(event, event.target.name)}>
                                                <option value="">{getLabel('LBL_All_statuses')}</option>
                                                {
                                                    parseInt(isPickup) ===1 ? <>
                                                    <option value={constants.ORDER_PICKUP_PENDING}>{getLabel('LBL_PICKUP_PENDING')}</option>
                                                    <option value={constants.ORDER_PICKUP_READY}>{getLabel('LBL_PICKUP_READY')}</option>
                                                    <option value={constants.ORDER_PICKED_UP}>{getLabel('LBL_PICKED_UP')}</option> 
                                                    </>
                                                            :
                                <>
                                <option value={constants.ORDER_UNSHIPPED}>{getLabel('LBL_Unshipped')}</option>
                                <option value={constants.ORDER_FLAGGED}>{getLabel('LBL_Flagged')}</option>
                                <option value={constants.ORDER_SHIPPED}>{getLabel('LBL_Shipped')}</option>
                                <option value={constants.ORDER_SHIPMENT_IS_CANCELLED}>{getLabel('LBL_Cancelled')}</option>
                                </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :   
                        ''
                    }
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="field-set">
                                <div className="caption-wraper">
                                    <label className="field_label">{getLabel('LBL_Date')}</label>
                                </div>
                                <div className="field-wraper">
                                    <div className="field_cover">
                                        <select name="date_selection" onChange={(event) => handleSelectChange(event, event.target.name)} >
                                            <option value="1">{getLabel('LBL_All_dates')}</option>
                                            <option value="2">{getLabel('LBL_Custom')}</option>
                                        </select>
                                    </div>
                                    {
                                        customDate
                                                &&
                            <div className="field_cover mt-2">
                                <input type="text" name='date' className='date_field' readOnly placeholder={`${dateConverter(startNewDate, 'datePicker')} - ${dateConverter(endNewDate, 'datePicker')}`} onClick={isOpen ? handleClosePicker : handleOpenPicker} />
                                <div className='filter_dropdown'>
    
                                    {
                                                                isOpen &&
                                                <>
                                                <DateRangePicker maxDate={new Date()} preventSnapRefocus={true} moveRangeOnFirstSelection={false} calendarFocus="backwards" ranges={[selectionRange]} months={2} direction="horizontal" onChange={handleSelect} showDateDisplay={false} inputRanges={[]} rangeColors={['#D31334']} weekdayDisplayFormat={'EEEEE'} />
                                                <div className='cancelApplyButton'>
                                                    <span>{`${dateConverter(startNewDate, 'datePicker')} - ${dateConverter(endNewDate, 'datePicker')}`}</span>
                                                    <button className='btn btn-cancel border-0' onClick={handleClosePicker}>{getLabel('LBL_Cancel')}</button>
                                                    <button className='btn btn-save' onClick={handleApplyDatePicker}>{getLabel('LBL_Apply')}</button>
                                                </div>
                                                </>
                                    }
    
                                </div>
                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-cancel' onClick={handleClose}>{getLabel('LBL_Cancel')}</button>
                    <button className='btn btn-save' onClick={handleSubmit}>{getLabel('LBL_Export')}</button>
                </Modal.Footer>
            </Modal>
            </>
            )
}
