import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom';
import LeftArrow from '../../../assets/images/leftArrow.svg'
import {viewOrder, shippingAddressDetails} from '../../../services/orders/index'
import { toast } from 'react-toastify';
import CommonContext from '../../../context/CommonContext'
import {getFilters} from '../../../services/orders/index'
import History from './History';
import {displayMoneyFormat,dateConverter,UniquePackagesArray,addProcessingLoader, removeProcessingLoader} from '../../../helpers/commonHelpers'
import PrintPacking from './PrintPacking';
import UpdateTrackingInfo from '../../Modal/views/UpdateTrackingInfo';
import UpdateShippingInfo from '../../Modal/views/UpdateShippingInfo';
export default function View() {
    const { id, channelId } = useParams();
    const [data,setData] = useState({})
    const {constants,shippingMethodObject,unshippedStatusArray,getLabel,shippingCompanyObject, setListingFilter, filterData} = useContext(CommonContext);
    const [status,setStatus] = useState([]);
    const [location,setLocation] = useState([]);
    const [uniqueStatuses, setUniqueStatuses] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openShippingModal, setOpenShippingModal] = useState(false);
    const [loadHistory, setLoadHistory] = useState(false);
    const [trackingData, setTrackingData] = useState({});
    const [addressData, setAddressData] = useState({});
    let subtotalAmount = 0;
    let totalShipping = 0;
    let totalTax = 0;
    let orderDiscount = 0;
    useEffect(()=>{
        getOrderDetails()
        getOrderStatus()
        // eslint-disable-next-line
    },[])

    const getOrderDetails = async() => {
        addProcessingLoader();
        try {
            const res = await viewOrder(id, channelId);
            setData(res.data)
            const orderStatuses = res.data.products.map((product) => (product.op_is_ship_completed === constants.ORDER_SHIPMENT_IS_CANCELLED ? constants.ORDER_CANCELLED : product.op_status_id));
            const uniqueStatuses = [...new Set(orderStatuses)];
            setUniqueStatuses(uniqueStatuses);
        } catch (error) {
            toast.error(error);
            window.history.back();
        }
        removeProcessingLoader();
    } 

    const getOrderStatus = async () => {
        try {
            const data = await getFilters();
            setStatus(data.orderStatus)
            setLocation(data.locations)
        } catch (error) {
            setStatus([])
        }
    }

    const handlePrint = () => {
        window.print();
    }

    const editModal = (packdata,trackingNumber, locationId) => {
        const request = {
            "location_id":parseInt(locationId),
            "tracking_number":Array(trackingNumber),
            "old_tracking_number":trackingNumber,
            "pack_id":packdata.opack_id,
            "shiping_company_id": packdata.opack_shipped_by,
            "shiping_method": packdata.opack_service_type,
            "opack_weight": packdata.opack_weight,
            "opack_height": packdata.opack_height,
            "opack_length": packdata.opack_length,
            "opack_width": packdata.opack_width
        } 
        setTrackingData(request);
        setOpenEditModal(true)
    }

    const ShippingEditModal = async() => {
        const request = {
            "order_id": id,
            "type":constants.SHIPPING_ADDRESS_TYPE,
        } 
        addProcessingLoader();
        try {
            const res = await shippingAddressDetails(request);
            removeProcessingLoader();
            if(res.status === false){
                toast.error(res.message);
                return;
            }
            setAddressData(res.data)
            setOpenShippingModal(true)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error)
        }
    }

    const backPage = () => {
        if(Object.keys(filterData).length > 0){
            setListingFilter(true);
        }
        window.history.back();
    }
    let UniquePackagesArrays = new Map();
    var mergedUniquePackagesArrays = UniquePackagesArrays;
    var additionalpackMap;
    if(Object.keys(data).length > 0){
        additionalpackMap = new Map(data.additionalpacks.map(item => [item.opack_id, item]));
    }
    return (
        <>
            {Object.keys(data).length <= 0 ? '' :
                <>
                    <div id="body" className='no-print'>
                        <div className="page space">
                            <div className="container">
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <div className='orderIdCss no-print'>
                                            <div onClick={backPage} className='no-print back-btn'>
                                                <img src={LeftArrow} alt="left arrow" className='no-print' />
                                            </div>
                                            <p>
                                                {getLabel('LBL_Order_ID:')} <img src={`/images/channels/${data.order_channel}.svg`} alt="channel-img" className="channelImgCss" /><span>{`${data.order_id} ${data.order_channel_oid ? '('+data.order_channel_oid+')' : ''}`}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-2 text-right'>
                                        <Link className="btn no-print printBtn" onClick={handlePrint}>{getLabel('LBL_Packing_slip')}</Link>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-9'>
                                        <div className="cards mb-5">
                                            <div className="orderInfo">
                                                <div className="topStatus">
                                                    <ul>
                                                        {uniqueStatuses.map((statusId) => {
                                                            return <li key={statusId}><img src={`/images/status/${statusId}.svg`} className="statusImg" alt={statusId} /> {status.map((Status) => (Status.orderstatus_id === statusId ? Status.status : null))}</li>
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className='table-responsive'>
                                                    <table className="table detail_page">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th width="25%">{getLabel('LBL_Product')}</th>
                                                                <th>{getLabel('LBL_SKU')}</th>
                                                                <th>{getLabel('LBL_Qty')}</th>
                                                                <th>{getLabel('LBL_Unit_Price')}</th>
                                                                <th>{getLabel('LBL_Subtotal')}</th>
                                                                <th>{getLabel('LBL_Tax')}</th>
                                                                <th></th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            
                                                            {data.products.map((product, index) => {
                                                                subtotalAmount += product.op_qty * product.op_unit_price;
                                                                totalShipping += product.op_actual_shipping_charges;
                                                                totalTax += parseFloat(data.order_channel > 0 ? product.op_total_tax:product.op_deducted_tax);
                                                                orderDiscount += product.op_discount_amt;
                                                                product.op_status_id = product.op_is_ship_completed === constants.ORDER_SHIPMENT_IS_CANCELLED ? constants.ORDER_CANCELLED : product.op_status_id;
                                                                return <React.Fragment key={index}>
                                                                    <tr className='product_row'>
                                                                        <td>
                                                                            <div className='item__pic'>
                                                                                <img src={product.images ? process.env.REACT_APP_IMAGE_BASE_URL+product.images[0].afile_physical_path : '/images/no_image.jpg'} alt="productImage" />
                                                                            </div>
                                                                        </td>
                                                                        <td className='item__description'>
                                                                            <div className='product_name'>{`${product.oProLang && product.oProLang.op_brand_name} ${product.oProLang && product.oProLang.op_product_name}`}</div>
                                                                            <div>{product.op_selprod_compatibility_text && product.op_selprod_compatibility_text}</div>
                                                                            <span className='variant_css'>{product.op_selprod_code &&  product.op_selprod_code}</span>
                                                                        </td>
                                                                        <td>{product.op_selprod_sku}</td>
                                                                        <td>
                                                                            <div className={`vertical-space ${product.op_refund_qty || product.cancel_qty ? 'line-through' : ''}`}>{product.op_qty}</div>
                                                                            {product.op_refund_qty || product.cancel_qty ? <div className="vertical-space refund-qty"><img src="/images/refund.svg" alt="refund" className='refund-img' />-{product.op_refund_qty + product.cancel_qty}</div> : '' }
                                                                        </td>
                                                                        <td>{displayMoneyFormat(product.op_unit_price)}</td>
                                                                        <td>{displayMoneyFormat(product.op_qty * product.op_unit_price)}</td>
                                                                        <td>{data.order_channel > 0 ? displayMoneyFormat(product.op_total_tax):displayMoneyFormat(product.op_deducted_tax)}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr className="package_detail">
                                                                        <td colSpan={7} className={`${product.cancel_qty || product.op_refund_qty ? 'statusMsgSpace' : ''} ${product.cancel_qty && product.op_refund_qty ? 'spaceForBoth' : ''}`}>
                                                                            <div className="row">
                                                                                {(unshippedStatusArray.includes(product.op_status_id) || product.op_status_id === constants.ORDER_CANCELLED || product.op_is_ship_completed === constants.ORDER_SHIPMENT_IS_CANCELLED) && product.shipdetails === null
                                                                                    ? 
                                                                                        <div className="package_info">
                                                                                            <div className="package_info_tabs">
                                                                                                <span className="tab_img">
                                                                                                    <img src={`/images/map.svg`} alt='location' />
                                                                                                    {product.orderLoc.loc_name}
                                                                                                </span>
                                                                                                <span >
                                                                                                    {product.orderLoc.loc_zip_code}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="package_info_tabs">
                                                                                                <span className="tab_img">
                                                                                                    <img src={`/images/dimension.svg`} alt='dimension' />
                                                                                                    -
                                                                                                </span>
                                                                                                <span>&nbsp;
                                                                                                    -
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="package_info_tabs">
                                                                                                <>
                                                                                                    <span className="tab_img">
                                                                                                        <img src={`/images/shipping-companies/default.svg`} className='service_img' alt='service' />
                                                                                                        -
                                                                                                    </span>
                                                                                                    <span >
                                                                                                        -
                                                                                                    </span>
                                                                                                    <span >
                                                                                                        -
                                                                                                    </span>
                                                                                                </>
                                                                                            </div>
                                                                                            <div className="package_info_status">
                                                                                                {status.map((Status) => (Status.orderstatus_id === product.op_status_id ? Status.status : null))}
                                                                                            </div>
                                                                                        </div> 
                                                                                    :
                                                                                        product.shipdetails && product.shipdetails.map((packData,packIndex)=>{
                                                                                            UniquePackagesArrays = UniquePackagesArray(packData,UniquePackagesArrays)
                                                                                            mergedUniquePackagesArrays = new Map([...UniquePackagesArrays, ...additionalpackMap]);
                                                                                            if(packData.ohistory_shipping_qty < 1){
                                                                                                return null;
                                                                                            }
                                                                                            return  packData.opack_id && packData.opack_tcode &&  JSON.parse(packData.opack_tcode).map((trackData,trackIndex) => (
                                                                                                    <React.Fragment key={`${packIndex}-${trackIndex}`}>
                                                                                                        <div className="package_info">
                                                                                                            <div className="package_info_tabs">
                                                                                                                <span className="tab_img">
                                                                                                                    <img src={`/images/map.svg`} alt='location' />
                                                                                                                    {product.orderLoc.loc_name}
                                                                                                                </span>
                                                                                                                <span >
                                                                                                                    {product.orderLoc.loc_zip_code}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="package_info_tabs pack-tab-css">
                                                                                                                <span className="tab_img">
                                                                                                                    <img src={`/images/dimension.svg`} alt='dimension' />
                                                                                                                    {packData.opack_id  ? `${parseFloat(packData.opack_length)} x ${parseFloat(packData.opack_width)} x ${parseFloat(packData.opack_height)} in` : `${parseFloat(product.op_product_length)} x ${parseFloat(product.op_product_width)} x ${parseFloat(product.op_product_height)} in`}
                                                                                                                </span>
                                                                                                                <span>&nbsp;
                                                                                                                    {packData.opack_id ? `${parseFloat(packData.opack_weight)} lb` : `${parseFloat(product.op_product_weight)} lb`}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className='shipping-tracking-info'>
                                                                                                                <div className="package_info_tabs">
                                                                                                                    <>
                                                                                                                        <span className="tab_img">
                                                                                                                            <img src={`/images/shipping-companies/${packData.opack_id ? packData.opack_shipped_by : 'default'}.svg`} className='service_img' alt='service' />
                                                                                                                            {packData.opack_id && (shippingMethodObject[packData.opack_service_type] ? shippingMethodObject[packData.opack_service_type] : shippingCompanyObject[packData.opack_shipped_by])}
                                                                                                                        </span>
                                                                                                                        {packData.opack_shipped_by === constants.CANPAR_SHIPPING_COMPANY && 
                                                                                                                            <span >
                                                                                                                                {packData.opack_id && packData.opack_total && packData.opack_total > 0 ? displayMoneyFormat(packData.opack_total) : '-'}
                                                                                                                            </span>
                                                                                                                        }
                                                                                                                        <span >
                                                                                                                            {trackData ? (trackData) : '-'}
                                                                                                                        </span>
                                                                                                                    </>
                                                                                                                </div>
                                                                                                                {trackData ? 
                                                                                                                    <div className='edit-track-btn' onClick={e =>editModal(packData,trackData, product.orderLoc.loc_id)}>
                                                                                                                        <img src="/images/edit.svg" alt="edit" />
                                                                                                                    </div>
                                                                                                                :
                                                                                                                    ''
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="package_info_status">
                                                                                                                {status.map((Status) => (Status.orderstatus_id === product.op_status_id ? Status.status : null))}
                                                                                                            </div>
                                                                                                        </div> 
                                                                                                    </React.Fragment>   
                                                                                                )
                                                                                            )
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                        {product.cancel_qty || product.op_refund_qty 
                                                                            ?
                                                                            <td>
                                                                                {product.cancel_qty 
                                                                                    ? 
                                                                                        <span className={`statusMsg ${product.cancel_qty && product.op_refund_qty ? 'refunded-warning' : ''}`}>
                                                                                            <img src={`/images/warning.svg`} alt='warning' />
                                                                                            {`${product.cancel_qty} x ${product.op_selprod_sku} was cancelled. Please ship out ${product.op_qty - product.cancel_qty}`}
                                                                                        </span>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                                {product.op_refund_qty 
                                                                                    ? 
                                                                                        <span className={`statusMsg`}>
                                                                                            <img src={`/images/warning.svg`} alt='warning' />
                                                                                            {`${product.op_refund_qty} x ${product.op_selprod_sku} was refunded. Please ship out ${product.op_qty - product.op_refund_qty - product.cancel_qty}`}
                                                                                        </span>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </td>
                                                                            : 
                                                                            ''
                                                                        }
                                                                    </tr>
                                                                </React.Fragment>
                                                            })}

                                                            <tr className="subtotal price">
                                                                <td colSpan={6}></td>
                                                                <td>{getLabel('LBL_Subtotal')}</td>
                                                                <td >{displayMoneyFormat(subtotalAmount)}</td>
                                                            </tr>
                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td> {getLabel('LBL_Shipping')}</td>
                                                                <td >{displayMoneyFormat(totalShipping)}</td>
                                                            </tr>
                                                            {orderDiscount > 0 && 
                                                                <tr className="price">
                                                                    <td colSpan={6}></td>
                                                                    <td> {getLabel('LBL_Discounts')}</td>
                                                                    <td>{displayMoneyFormat(orderDiscount)}</td>
                                                                </tr>
                                                            }
                                                            
                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td>{getLabel('LBL_Tax')}</td>
                                                                <td>{displayMoneyFormat(totalTax)}</td>
                                                            </tr>
                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td> {getLabel('LBL_Total')}</td>
                                                                <td>{displayMoneyFormat(subtotalAmount+totalShipping+totalTax+orderDiscount)} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row no-print'>
                                            <div className='col-md-12'>
                                                <div className="cards">
                                                    <div className="orderInfo">
                                                        <div className='auditHistory'>
                                                            <History id={id} constants={constants} location={location} shippingMethodObject={shippingMethodObject} getLabel={getLabel} loadData={loadHistory} setLoadData={setLoadHistory} isPreOrder={false}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-md-3'>
                                        <div className="cards">
                                            <div className="orderInfo">
                                                <div className='customer_section_css'>
                                                    <div className='right_heading'>
                                                        {getLabel('LBL_Customer')}
                                                    </div>
                                                    <span className='user_name_css'>{`${data.buyer.obuyer_fname ? data.buyer.obuyer_fname : ''} ${data.buyer.obuyer_lname ? data.buyer.obuyer_lname: ''}`}</span>
                                                </div>
                                                <hr />
                                                <div className='shipping_section_css'>
                                                    <div className='shipping-side-info'>
                                                        <div className='right_heading'>
                                                            {getLabel('LBL_Shipping_address')}
                                                        </div>
                                                        <div className='right_heading'>
                                                            <img src="/images/edit.svg" alt="edit" onClick={ShippingEditModal}/>
                                                        </div>
                                                    </div>
                                                    <span>
                                                        {data.address.oua_name ?
                                                            (
                                                                <>
                                                                    {data.address.oua_name} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_company_name ?
                                                            (
                                                                <>
                                                                    {data.address.oua_company_name} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_address1 ?
                                                            (
                                                                <>
                                                                    {data.address.oua_address1} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_address2 ?
                                                            (
                                                                <>
                                                                    {data.address.oua_address2} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_city ?
                                                            (
                                                                <>
                                                                    {data.address.oua_city},&nbsp;
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_state ?
                                                            (
                                                                <>
                                                                    {data.address.oua_state}&nbsp;
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_zip ?
                                                            (
                                                                <>
                                                                    {data.address.oua_zip} <br />
                                                                </>
                                                            ) : ''
                                                        }

                                                        {data.address.oua_country ?
                                                            (
                                                                <>
                                                                    {data.address.oua_country} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_phone ?
                                                            (
                                                                <>
                                                                    {data.address.oua_phone} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                    </span>
                                                </div>
                                                <hr />
                                                <div className='shipment_section_css'>
                                                    <div className='right_heading'>
                                                        {getLabel('LBL_Shipment')}
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <div className='shipment_icon'><img src={`/images/shipment-icons/purchase.svg`} alt="purchase" /></div>
                                                            <span className='title'>{getLabel('LBL_Purchase')}</span>
                                                            <span className='value'>{dateConverter(data.order_date_added, 'dateYMD')}</span>
                                                        </li>
                                                        {Array.from(mergedUniquePackagesArrays.values()).map((item, index) => {
                                                            return <React.Fragment key={index}>
                                                                {
                                                                    item.opack_id
                                                                    &&
                                                                    <>
                                                                        <li>
                                                                            {getLabel('LBL_Package')} {index+1}
                                                                        </li>
                                                                        <li >
                                                                            <div className='shipment_icon'><img src={`/images/shipment-icons/shipment.svg`} alt="Shipment" /></div>
                                                                            <span className='title'>{getLabel('LBL_Shipment')}</span>
                                                                            <span className='value'>{item.opack_id && dateConverter(item.opack_shipping_date, 'dateYMD')}</span>
                                                                        </li>
                                                                        <li>
                                                                            <div className='shipment_icon'><img src={`/images/shipment-icons/transport.svg`} alt="transport" /></div>
                                                                            <span className='title'>{getLabel('LBL_Service')}</span>
                                                                            <span className='value'>{(shippingMethodObject[item.opack_service_type] ? shippingMethodObject[item.opack_service_type]: shippingCompanyObject[item.opack_shipped_by]) }</span>
                                                                        </li>
                                                                    </>
                                                                }
                                                                {
                                                                    item.opack_id && item.opack_from_name
                                                                    && 
                                                                    <li>
                                                                        <div className='shipment_icon'><img src={`/images/shipment-icons/location.svg`} alt="location" /></div>
                                                                        <span className='title'>{getLabel('LBL_Fulfillment')}</span>
                                                                        <span className='value'>{item.opack_from_name}</span>
                                                                    </li>
                                                                }
                                                                {
                                                                    item.opack_id && item.opack_tcode
                                                                    && 
                                                                    <li>
                                                                        <div className='shipment_icon'><img src={`/images/shipment-icons/tracking.svg`} alt="location" /></div>
                                                                        <span className='title'>{getLabel('LBL_Tracking_number')}</span>
                                                                        <span className='value track_number'>
                                                                        {
                                                                            item.opack_tcode && Object.values(JSON.parse(item.opack_tcode)).map((trackCode,index) => {

                                                                                const trackUrl = item.scompany_trackingurl.replace('{code}', trackCode);
                                                                                
                                                                                return <Link key={index} className='ship link' to={trackUrl} target="_blank">{trackCode}{index !== Object.values(JSON.parse(item.opack_tcode)).length - 1 && ', '}</Link>
                                                                            })
                                                                        }
                                                                        </span>
                                                                    </li>
                                                                }
                                                            </React.Fragment>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {openEditModal && <UpdateTrackingInfo show={openEditModal} setShow={setOpenEditModal} data={trackingData} getOrderStatus={getOrderStatus} getOrderDetails={getOrderDetails} setLoadHistory={setLoadHistory} locations={location} />}
                        {openShippingModal && <UpdateShippingInfo show={openShippingModal} setShow={setOpenShippingModal} data={addressData} setAddressData={setAddressData} pageReload={getOrderDetails} />}
                    </div>

                    <div className='print'>
                        <PrintPacking data={data} uniquePackagesArrays={UniquePackagesArrays}/>
                    </div>
                </>
            }
        </>
    )
}
