import React, { useContext } from 'react'
import CommonContext from '../../../context/CommonContext'
import { displayMoneyFormat, dateConverter, getUniquePickupLocations } from '../../../helpers/commonHelpers'
import logo from '../../../assets/images/sap-full-logo.png'
export default function PrintPacking(props) {
    const { data } = props
    const { channelObject, getLabel } = useContext(CommonContext);
    let subtotalAmount = 0;
    let totalShipping = 0;
    let totalTax = 0;
    let orderDiscount = 0;
    let pickupDate = [];

    data.products.forEach((product) => {
        if (product.shipdetails) {
            pickupDate.push(dateConverter(product.shipdetails[0].ohistory_added_on, 'dateYMD'))
            pickupDate = [...new Set(pickupDate)]
        }
    });

    return (
        <>
            <div id="body" className='print-item'>
                <div className="page space">
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <img src={logo} alt="logo" className="top-logo" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='print_order_id_css'>
                                    <p>
                                        <span className='print_order_invoice_clr_css'>{getLabel('LBL_Order_#')} </span>{data.order_id}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <table className="top-table-css">
                            <tbody>
                                <tr>
                                    <th>{getLabel('LBL_Platform')}</th>
                                    <td>{channelObject[data.order_channel]}</td>
                                </tr>
                                <tr>
                                    <th>{getLabel('LBL_Order_date')}</th>
                                    <td>{dateConverter(data.order_date_added, 'dateYMD')} (EST)</td>
                                </tr>

                                {pickupDate.length > 0 && pickupDate.map((date, index) => {
                                    return <tr key={index}>
                                        <th>{getLabel('LBL_Pick_up')}</th>
                                        <td> {date} </td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                        {data.pickupLocations.length > 0 && getUniquePickupLocations(data.pickupLocations).map((loc, index) => (
                            <div className='shipping_section_css' key={index}>
                                <h6>
                                    {getLabel('LBL_Pickup_Address') + ' ' + (index >= 1 && data.pickupLocations.length > 1 ? ++index : '')}
                                </h6>
                                <span>
                                    {loc.ploc_name ?
                                        (
                                            <>
                                                {loc.ploc_name} <br />
                                            </>
                                        ) : ''
                                    }

                                    {loc.ploc_address_1 ?
                                        (
                                            <>
                                                {loc.ploc_address_1} <br />
                                            </>
                                        ) : ''
                                    }
                                    {loc.ploc_address_2 ?
                                        (
                                            <>
                                                {loc.ploc_address_2} <br />
                                            </>
                                        ) : ''
                                    }
                                    {loc.ploc_city ?
                                        (
                                            <>
                                                {loc.ploc_city},&nbsp;
                                            </>
                                        ) : ''
                                    }
                                    {loc.ploc_state ?
                                        (
                                            <>
                                                {loc.ploc_state}&nbsp;
                                            </>
                                        ) : ''
                                    }
                                    {loc.ploc_zip_code ?
                                        (
                                            <>
                                                {loc.ploc_zip_code} <br />
                                            </>
                                        ) : ''
                                    }

                                    {loc.ploc_country ?
                                        (
                                            <>
                                                {loc.ploc_country} <br />
                                            </>
                                        ) : ''
                                    }
                                    {loc.ploc_phone ?
                                        (
                                            <>
                                                {loc.ploc_phone} <br />
                                            </>
                                        ) : ''
                                    }
                                </span>
                            </div>
                        ))}

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='line'></div>
                                <table className="table detail_page">
                                    <thead>
                                        <tr>
                                            <th>{getLabel('LBL_Product')}</th>
                                            <th></th>
                                            <th width="15%">{getLabel('LBL_Qty')}</th>
                                            <th width="15%">{getLabel('LBL_Unit_Price')}</th>
                                            <th style={{ textAlign: 'center' }}>{getLabel('LBL_Subtotal')}</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {data.products.map((product, index) => {
                                            subtotalAmount += product.op_qty * product.op_unit_price;
                                            totalShipping += product.op_actual_shipping_charges;
                                            totalTax += parseFloat(data.order_channel > 0 ? product.op_total_tax : product.op_deducted_tax);
                                            orderDiscount += product.op_discount_amt;
                                            return <React.Fragment key={index}>
                                                <tr className='product_row'>
                                                    <td>
                                                        <div className='item__pic'>
                                                            <img src={product.images ? process.env.REACT_APP_IMAGE_BASE_URL + product.images[0].afile_physical_path : '/images/no_image.jpg'} alt="productImage" />
                                                        </div>
                                                    </td>
                                                    <td className='item__description'>
                                                        <div className='product_name'>{`${product.oProLang && product.oProLang.op_brand_name} ${product.oProLang && product.oProLang.op_product_name}`}</div>
                                                        <div >{product.op_selprod_compatibility_text && product.op_selprod_compatibility_text}</div>
                                                        <div className='variant_css'>{product.op_selprod_code && product.op_selprod_code}</div>
                                                        <div className='variant_css'>{product.op_selprod_sku}</div>
                                                    </td>
                                                    <td>{product.op_qty - product.cancel_qty - product.op_refund_qty}</td>
                                                    <td>{displayMoneyFormat(product.op_unit_price)}</td>
                                                    <td style={{ textAlign: 'right' }}>{displayMoneyFormat(product.op_qty * product.op_unit_price)}</td>
                                                </tr>
                                                <tr className='line'>
                                                    <td colSpan={5}></td>
                                                </tr>

                                            </React.Fragment>
                                        })}

                                        <tr className="subtotal price">
                                            <td colSpan={2}></td>
                                            <td> {getLabel('LBL_Subtotal')}</td>
                                            <td colSpan={2} style={{ textAlign: 'right' }}>{displayMoneyFormat(subtotalAmount)}</td>
                                        </tr>
                                        <tr className="price">
                                            <td colSpan={2}></td>
                                            <td> {getLabel('LBL_Shipping')}</td>
                                            <td colSpan={2} style={{ textAlign: 'right' }}>{displayMoneyFormat(totalShipping)}</td>
                                        </tr>
                                        {orderDiscount > 0 &&
                                            <tr className="price">
                                                <td colSpan={2}></td>
                                                <td> {getLabel('LBL_Discounts')}</td>
                                                <td colSpan={2} style={{ textAlign: 'right' }}>{displayMoneyFormat(orderDiscount)}</td>
                                            </tr>
                                        }
                                        {totalTax > 0 &&
                                            <tr className="price">
                                                <td colSpan={2}></td>
                                                <td>{getLabel('LBL_Tax')}</td>
                                                <td colSpan={2} style={{ textAlign: 'right' }}>{displayMoneyFormat(totalTax)}</td>
                                            </tr>
                                        }
                                        <tr className="price total">
                                            <td colSpan={2}></td>
                                            <td> {getLabel('LBL_Order_Total')}</td>
                                            <td colSpan={2} style={{ textAlign: 'right' }}>{displayMoneyFormat(subtotalAmount + totalShipping + totalTax + orderDiscount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
