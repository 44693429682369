import {myAxios,headers} from '../../helpers/commonHelpers';
import { toast } from 'react-toastify';
import { addProcessingLoader, removeProcessingLoader } from '../../helpers/commonHelpers';
import { dateConverter } from '../../helpers/commonHelpers';
export const getFilters = async () => {
    return new Promise((resolve, reject) => {
        myAxios.get('/order/filters',{headers: headers()}).then((res)=>{
            resolve(res.data.data);
        }).catch((error)=>{
            reject(false);
        })
    });
}

export const getUnshippedCount = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios.get(`/order/order-count/${id}`,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            reject(false);
        })
    });
}

export const cancelOrder = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post('/order/cancel-order',data,{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                toast.success(res.data.message);
                resolve(true);
            }else{
                toast.error(res.data.message);
                resolve(false);
            }
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
                reject(error.response.data.message);
            }

        })
    });
}
export const printPacking = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post('/order/print-invoice',data,{headers: headers()}).then((res)=>{
            if(res.data.status === false){
                toast.error(res.data.message)
            }
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }

        })
    });
}


export const exportData = async (data, isCourierRateCall) => {
    var func = '/order/download-csv';
    var fileName = 'orders';
    if(isCourierRateCall === true){
        func = '/order/download-ship-rates-csv';
        fileName = 'strictlyautoparts_fulfillment_rates';
        if(data.date_from && data.date_to){
            fileName = 'strictlyautoparts_fulfillment_rates_' + dateConverter(data.date_from,'dateYMDExport') + '-' + dateConverter(data.date_to,'dateYMDExport');
        }
    }
    addProcessingLoader();
    try {
        const response = await myAxios.post(func,data, {headers: headers(),responseType: 'blob'});

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName+'.xlsx';
        link.click();

        URL.revokeObjectURL(url);
    } catch (error) {
        toast.error('Error downloading CSV:', process.env.REACT_APP_COMMON_ERROR);
    }
    removeProcessingLoader();
};


export const viewOrder = async (id,type) => {
    return new Promise((resolve, reject) => {
        myAxios.get(`/order/details/${id}/${type}`,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }else{
                reject(error.response.data.message);
            }
        })
    });
}


export const addNotes = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/order/add-notes`,data,{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                toast.success(res.data.message);
                resolve(true);
            }else{
                resolve(res.data.message);
            }
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }else{
                reject(error.response.data.message);
            }
        })
    });
}

export const getHistory = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/order/get-history`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const markAsRead = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/order/note-read`,data,{headers: headers()}).then((res)=>{
            resolve(true);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const getLabel = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/package/get-Label`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const createShipmentWithlabel = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/package/create-shipment-with-labels`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const createMultipleShipment = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/package/create-multiple-shipment`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const updateTrackInfo = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/package/update-package`,data,{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                toast.success(res.data.message)
                resolve(true);
                return;
            }
            toast.error(res.data.message);
            resolve(false);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const orderLabelPrint = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/package/label-print`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}
export const shippingAddressDetails = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/order-address/address-detail`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const updateAddress = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/order-address/update`,data,{headers: headers()}).then((res)=>{
            toast.success(res.data.message)
            resolve(true);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const changePickupStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/pickup-order/update-status`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
};

export const markOrderShipped = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post('/order/mark-shipped',data,{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                toast.success(res.data.message);
                resolve(true);
            }else{
                toast.error(res.data.message);
                resolve(false);
            }
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
                reject(error.response.data.message);
            }
        })
    });
}

/* Pre And Back Orders Api's */
export const viewPreOrder = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios.get(`/pre-order/details/${id}`,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }else{
                reject(error.response.data.message);
            }
        })
    });
}

export const confirmOrder = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post('/pre-order/confirm-order',data,{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                toast.success(res.data.message);
                resolve(true);
            }else{
                toast.error(res.data.message);
                resolve(false);
            }
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
                reject(error.response.data.message);
            }
        })
    });
}